<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar">
    <!-- <div class="container d-flex justify-content-center" style="background-color: #dd8a3b;">
      <div class="row">
        <div class="col-lg-12">
          <div class="header-contact">
            <ul>
              <li>Welcome to Akstex Africa</li>
            </ul>
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <app-left-menu (isOpen)="setOverlay($event)"></app-left-menu>
            <div class="brand-logo">
              <a routerLink="/home/fashion" id="headerlogo">
                <img [src]="themeLogo" class="img-fluid" alt="logo">
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
              <!-- <app-menu></app-menu> -->
            </div>
            <div style="width: 110px;">
              <app-settings></app-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
