// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stripe_token: 'STRIPE_TOKEN',
  paypal_token: 'PAYPAL_TOKEN',
  firebase: {
    apiKey: "AIzaSyBt6kGZqrdHwsFCQDWFU_tHBlULxpHtM4w",
    authDomain: "akstex-africa.firebaseapp.com",
    projectId: "akstex-africa",
    storageBucket: "akstex-africa.appspot.com",
    messagingSenderId: "829755177488",
    appId: "1:829755177488:web:102ce1901a66b10d5ae895",
    measurementId: "G-LV3WL9C8ZT"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
