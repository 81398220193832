import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ShopComponent } from './shop/shop.component';
import { PagesComponent } from './pages/pages.component';
import { ElementsComponent } from './elements/elements.component';
import { NgxSeo } from '@avivharuzi/ngx-seo';

const SEO_HOME: NgxSeo = {
  title: 'Akstex Africa',
  meta: {
    description: `Welcome to AKSTEX Africa Your Trusted Source for Premium Fabrics
    At AKSTEX Africa, we are passionate about bringing you the finest selection of fabrics that blend quality, elegance, and affordability. Our carefully curated collection showcases the rich diversity and vibrant heritage of African textiles, making us your go-to destination for all your fabric needs.
    Whether you're a fashion designer, tailor, or craft enthusiast, you'll find an array of stunning prints, luxurious textures, and unique patterns that inspire creativity. We take pride in offering fabrics that not only look beautiful but also stand the test of time.
    Explore our wide range of fabrics, from traditional African prints to modern, contemporary designs. At AKSTEX Africa, we believe in delivering exceptional customer service, ensuring that every piece of fabric you choose is of the highest quality and meets your exact specifications.
    Join our community of satisfied customers and let AKSTEX Africa be a part of your creative journey. Discover the perfect fabric for your next project today!`,
  },
};

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'home',
  //   pathMatch: 'full'
  // },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    data:{ seo: SEO_HOME }
  },
  {
    path: 'shop',
    component: ShopComponent,
    loadChildren: () => import('./shop/shop.module').then(m => m.ShopModule),
    data:{ seo: SEO_HOME }
  },
  { 
    path: 'pages',
    component: PagesComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule) 
  },
  { 
    path: 'elements', 
    component: ElementsComponent,
    loadChildren: () => import('./elements/elements.module').then(m => m.ElementsModule) },
  {
    path: '**', // Navigate to Home Page if not found any page
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
