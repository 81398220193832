<div class="img-wrapper">
	<div class="lable-block">
		<!-- <span class="lable3" *ngIf='product.new'>new</span>
		<span class="lable4" *ngIf='product.sale'>on sale</span> -->
	</div>
	<div class="front">
        <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]" class="image-container">
            <img [defaultImage]="ImageSrc ? 'https://digitaldistrict.co.za/akstex/images/'+ImageSrc : 'assets/images/product/placeholder.jpg'" 
                 [lazyLoad]="ImageSrc ? 'https://digitaldistrict.co.za/akstex/images/'+ImageSrc : 'https://digitaldistrict.co.za/akstex/images/'+product.images[0].src" 
                 class="img-fluid lazy-loading" alt="">
        </a>
	</div>
	<div class="cart-box">
		<a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
            <i class="ti-search" aria-hidden="true"></i>
        </a>
        <!-- <button title="Add to cart" (click)="CartModal.openModal(product)" *ngIf="cartModal">
            <i class="ti-shopping-cart"></i>
        </button>
        <button title="Add to cart" (click)="addToCart(product)" *ngIf="!cartModal">
            <i class="ti-shopping-cart"></i>
        </button> -->
	</div>
</div>
<div class="product-detail text-center mt-2">
    <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
        <h6>{{ product.title | titlecase }}</h6>
    </a>
    <h4>
        <del *ngIf="product?.discount">{{product?.price * currency?.price | currency:currency?.currency:'symbol'}}</del>
        {{ product?.price * currency?.price | discount:product | currency:currency?.currency:'symbol' }}
    </h4>
</div>

<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>